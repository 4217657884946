import { render, staticRenderFns } from "./Prioridades.vue?vue&type=template&id=7174d920&style=margin%3A0px%3Bpadding%3A0px%3B&"
import script from "./Prioridades.vue?vue&type=script&lang=js&"
export * from "./Prioridades.vue?vue&type=script&lang=js&"
import style0 from "./Prioridades.vue?vue&type=style&index=0&id=7174d920&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports