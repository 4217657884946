<template style="margin:0px;padding:0px;">
    <v-container style="padding-top:0px!important;padding-left:20px;margin-left:0px;padding-right:20px!important;margin-right:0px!important;width:100%!important;min-width:100%;">
       
        <div>
            <div class="menuBotes">
            
                                            <v-btn small elevation="" color="info"  :outlined="outlinedBtnProjetos" @click="exibirModaisProjetos()" >Projetos</v-btn>
                    
                                              <v-btn small elevation="" color="info"  :outlined="outlinedBtnAtividadesAtribuidas"   @click="exibirModais()" >Atividades</v-btn>

                                              
                                      
                                              <v-btn small elevation="" color="info"  outlined @click="$router.push('/agenda')" >Ver Agenda</v-btn>

                                              <v-btn small elevation="" color="info"  outlined  @click="downloadFile()" >Exportar tabelas para excel</v-btn>
                                      
            </div>
               
        </div>
            
            
    

        <div>
            <v-dialog v-model="exibirModalExportarParaExcel" width="80vw" max-width="500px">
                    <div class="containerDetalhesPrioridades" style="background-color:white">
                        
                                <h3>Selecione a tabela que deseja exportar</h3>
                                <v-col cols="12" md="70">
                             <v-select class="select-input" color v-model="tabelaSelecionada" label="Tabelas" item-text="nome" 
                             :items="tabelas"
                              outlined return-object hide-details dense>
                                    </v-select>
                            </v-col>
                                <button class="cancelarDeletarPrioridade" type="button" @click="exportarTabela">Exportar</button>
                                <button class="cancelarDeletarPrioridade" type="button" @click="() => exibirModalExportarParaExcel=false">Cancelar</button>
                    </div>
                </v-dialog>
               
                <v-dialog v-model="exibirModalFiltrosRelatoriosAtividades" width="80vw" max-width="700px">
                    <div class="containerFiltroRelatorio" style="background-color:white">
                                <div class="containerTituloAtividadeExportar">
                                  <h2 class="TituloModalExportarAtividade">Relatório de atividades</h2>
                                </div>
                                <div class="containerFiltrosAtividades">
                                  <h4 id="labelDateRelatorioFiltro">Data de Estimativa de Entrega</h4>
                                <div class="ContainerinputDataEstimativa">
                                     inicial:
                                    <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataEstimativa.data_inicial"/>
                                    final:
                                  <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataEstimativa.data_final"/>
                                </div>
                                <h4 id="labelDateRelatorioFiltro">Data de Conclusão</h4>
                                <div class="ContainerinputDataEstimativa">
                                     inicial:
                                    <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataConclusao.data_inicial"/>
                                    final:
                                  <input class="textFieldDateFiltroRelatorio" type="date" v-model="dataConclusao.data_final"/>
                                </div>
                                <h4>Status</h4>
                                <div class="ContainerinputDataEstimativa">
                                  <input type="radio" class="inputRadio1" v-model="status" id="html" name="status" value="Todos"> Todos 
                                  <input type="radio" class="inputRadio" v-model="status" id="css" name="status" value="atrasada"> Atrasadas 
                                  <input type="radio" class="inputRadio" v-model="status" id="javascript" name="status" value="pendente"> Pendentes  
                                  <input type="radio" class="inputRadio" v-model="status" id="javascript" name="status" value="concluidas"> Concluídas 
                                </div>
                                <h4>Atribuidas</h4>
                                <div class="ContainerinputDataEstimativa">
                                  <input type="radio" class="inputRadio1" v-model="Atribuidas" id="html" name="atribuidas" value="Todos"> Todos  
                                  <input type="radio" class="inputRadio" v-model="Atribuidas"  id="css" name="atribuidas" value="sim"> Sim  
                                  <input type="radio" class="inputRadio" v-model="Atribuidas"  id="javascript" name="atribuidas" value="não"> Não
                                </div>
                                <button class="buttonExportarAtividade" type="button" @click="concluirExportarTabelaAtividades">Exportar</button>
                                <button class="buttonExportarAtividade" type="button" @click="() => exibirModalFiltrosRelatoriosAtividades=false">Cancelar</button>
                                </div>
                    </div>
                </v-dialog>
              
             <v-container v-show="true" class="" style="padding-left:0px;margin-left:0px;padding-right:0px!important;margin-right:0px!important;width:100vw!important;min-width:100%;">
                <v-row>

                    <!-- GRAFICO DE BARRA DE QUANTIDADES -->
                    <v-col  v-show="modalAtividadesEPrioridades" >
                        <v-card class="" elevation="" max-width="">
                          <v-card-text>
                              <div style="background-color:white;">
       

                                      <v-container class="">
                                         
                                             <div style="display:flex; flex-direction: column;">
                                                <div class="containerBtnNovasPrioridades">
                                                            <v-btn small elevation="" color="info" 
                                                            v-show="esconderTabelaAtividades"
                                                            @click="novaAtividade()">Adicionar novas atividades</v-btn>
                                                  
                                                            <v-btn small elevation="" color="info"
                                                            v-show="verPrioridadesAtribuidas" @click="novaPrioridade()">Adicionar novos Projetos</v-btn>
                                                </div>
                                                 
                                                 <div style="display:flex;margin-bottom:10px;">
                                                     <div style="display:flex;align-items:flex-end;width:50%;" >
                                                         <strong style="">{{esconderTabelaAtividades ? "Atividades disponíveis":"Projetos disponíveis"}}</strong>
                                                     </div>
                
                                                 </div>
                                                 
                                                        <v-data-table :items-per-page="5" v-show="esconderTabelaAtividades" :headers="headers" :items="listadeatividades " class="elevation-1" >
                                                                <template v-slot:[`item.actions`]="{item}">
                                                                    <div style="display:flex;" v-if="!item.fl_concluida">
                                                                        <v-btn small elevation="" @click="selecionarUsuarioParaAtribuir(item)" color="info">
                                                                            <v-icon color="">mdi-arrow-right-circle</v-icon>
                                                                        </v-btn>
                                                                        <v-btn v-if="mayChange(item)" small class="ml-2" style="text-align:center;"
                                                                            @click="editarAtividade(item)"
                                                                        >
                                                                            <v-icon color="green" title="Editar">
                                                                                mdi-pencil
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.titulo`]="{ item }">
                                                                    <div>
                                                                        <div><h4 style="color:#666666;">{{item.titulo}}</h4></div>
                                                                        <div>{{item.descricao}}</div>
                                                                        
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.dt_entrega`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.dt_entrega | formatarData}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.tipo`]="{ item }">
                                                                    <div> 
                                                                        <img class="imgBug" v-if="item.tipo === 'Bug'" src="/Prioridades/iconeBug.png" title="Bug"/>
                                                                        <img class="imagemFeature" v-if="item.tipo === 'Nova Funcionalidade'" src="/Prioridades/iconFeature.png" title="Nova funcionalidade"/>
                                                                        <img v-if="item.tipo === null" class="imgPrioridadeNull" src="/Prioridades/iconeNull.png"/>
                                                                        <img v-if="item.tipo === 'Meets'" class="imgmeet" src="/Prioridades/meet.png" title="Meets"/>
                                                                        <img v-if="item.tipo === 'Testes'" class="imgteste" src="/Prioridades/teste.png"
                                                                        title="Testes"
                                                                        />
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.prioridade`]="{ item }">
                                                                    <div> 
                                                                        <button v-if="item.prioridade" type="button" @click="verPrioridadeAtividade(item.prioridade)">
                                                                            <img class="imgPrioridade" src="/Prioridades/4965408.png" :title="item.prioridade"/>
                                                                        </button>
                                                                        <img v-else class="imgPrioridadeNull" src="/Prioridades/iconeNull.png" title="Nenhuma"/>
                                                                    </div>
                                                                </template>

                                                        </v-data-table>
                                                    </div>
                                                    
                                              <div class="divFiltrosAtividades">
                                            <input type="text" class="inputFiltroAtividades" :placeholder="radioSelecionadoFiltroProjetos" @keyup="projetosFiltros" v-model="textoInputFiltroProjetos"/>
                                            <div class="divRadiosFiltrosAtividades">
                                              <label class="labelRadio">Título</label>
                                              <input type="radio" v-model="radioSelecionadoFiltroProjetos" class="RadioTitulo" checked name="fav_language2" value="Título">
                                              <label  class="labelRadio" for="css">Id</label>
                                              <input type="radio" v-model="radioSelecionadoFiltroProjetos" name="fav_language2" value="Id">
                                            </div>
                                          </div>


                                                    <div >

                                                
                                                      
                                                   
                                                 <v-data-table 

                                                 v-show="verPrioridadesAtribuidas"
                                                :headers="headers2" style="margin-top: 10px;"
                                                :items="prioridades"
                                                :items-per-page="5" 
                                                class="elevation-1"
                                             >
                                             <template v-slot:[`item.percentualconcluido`]="{ item }">
                                                                    <div> 
                                                                      <p>{{item.percentualconcluido}}%</p>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.data_criacao`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.data_criacao | formatarData}}</div>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:[`item.data_conclusao_estimada`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.data_conclusao_estimada | formatarData}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.acoes`]="{ item }">
                                                                    <div class="containerAcoesProjetos">
                                                                        <v-btn v-if="mayChange(item)" small class="ml-2" style="text-align:center;"
                                                                             @click="editarProjeto(item)"
                                                                         >
                                                                             <v-icon color="green" title="Editar">
                                                                                 mdi-pencil
                                                                             </v-icon>
                                                                         </v-btn>
                                                                       <button class="buttonDeletePrioridade" type="button" @click="deletarPrioridade(item)"><img class="iconeDeletarPrioridade" src="/Prioridades/deletarPrioridade.png" alt="deletar prioridade"></button>
        
                                                                    </div>
                                                                </template>
                                                            </v-data-table> 
                                                        </div>
                                                        
                                                    </v-container>                                                    
                                                </div>
                            
           <v-dialog v-model="exibirModalEditarPrioridade" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>

                      <v-col cols="12" md="12">
                            <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="prioridadeEditar.titulo" outlined return-object hide-details dense>
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                            <v-textarea label="Descrição"  v-model="prioridadeEditar.descricao" outlined return-object hide-details dense >
                            </v-textarea> 
                            
                      </v-col>
                      <v-col cols="4">
                            <v-text-field type="date" label="Data de criação" 
                                v-model="prioridadeEditar.dataCriacao" outlined dense hide-details 
                                :min="dataHoje()"
                            >
                            </v-text-field>
                      </v-col>

                      <v-col cols="4">
                            <v-text-field type="date" label="Data para conclusão" 
                                v-model="prioridadeEditar.dataConclusao" outlined dense hide-details 
                                :min="dataHoje()"
                            >
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                           
                              <v-row>

                                  
                                  <v-col>
                                      <v-btn elevation="" @click="() => salvarProjetoEditado()" color="info" block>Salvar</v-btn> 
                                    </v-col>
                                    <v-col>
                                        <v-btn elevation="" @click="() => exibirModalEditarPrioridade=false" color="error" block> Cancelar</v-btn> 
                                    </v-col>
                            </v-row>

                      </v-col>

                    </v-row>
                  </v-container>
              </div>
              
          </div>
    </v-dialog>
                <v-dialog v-model="exibirModalDeletarPrioridade" width="80vw" max-width="500px">
                    <div class="containerDetalhesPrioridades" style="background-color:white">
                        
                                <h2>Essa prioridade possui {{atividadePrioridade.qtdeatividades}} atividades</h2>
                                <p>Deseja realmente deletar essa prioridade?</p>
                                <button class="deletarPrioridadeBtn" type="button" @click="confirmarDeletarPrioridade">Deletar</button>
                                <button class="cancelarDeletarPrioridade" type="button" @click="() => exibirModalDeletarPrioridade=false">Cancelar</button>
                    </div>
                </v-dialog>
                          </v-card-text>
                          <!-- <v-card-text>
                              <div style="text-align:end;">
                                  <h4>
                                  Total:{{listaClienteQtdePorStatus.totalClientes}}
                                  </h4>
                              </div>
                          </v-card-text> -->
                        </v-card>
                    </v-col>
                    <!-- FIM DO GRAFICO DE BARRA DE QUANTIDADES -->

                  <v-col  v-show="modalPrioridadesAtribuidas" >
                    <v-card class="" elevation="" max-width="">
                          <!-- <v-card-title> Prioridades Atribuidas a {{usuario.nome}} </v-card-title> -->
                          <v-card-text >


                            <v-col cols="12" md="6">
                            <!-- <v-card-title style="display:flex">Prioridades atribuídas a:{{usuario.nome}}</v-card-title> -->
                          <!-- <v-card-title> Prioridades Atribuidas a {{usuario.nome}} </v-card-title> -->
                          <v-col cols="12" md="70" >
                             <v-select class="select-input" color v-model="usuario" label="Usuario implantador" item-text="nome" 
                             item-value="id" :items="listadeusuarios"
                             @change="buscarAtribuidasPendentes({idusuario:usuario.id})" outlined return-object hide-details dense>
                                    </v-select>
                            </v-col>
                            </v-col>

                            <v-dialog v-model="exibirModalFiltros" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            <div class="ContainerFiltroAtividades">
              <div>
                                  <input type="radio" class="inputRadio1" v-model="filtroAtividades" id="disponiveis" name="atribuidas" value="disponiveis"> Disponiveis
                                  <input type="radio" class="inputRadio" v-model="filtroAtividades"  id="atribuidas" name="atribuidas" value="pendentes"> Atribuída pendentes  
                                  <input type="radio" class="inputRadio" v-model="filtroAtividades"  id="javascript" name="atribuidas" value="concluidas"> Atribuída concluídas
                                </div>
                                <div>
                                  <v-btn color="info" class="buttonFiltrarAtividade"
                                @click="() => this.concluirFiltroAtividades()">Concluir</v-btn>
                                <v-btn color="info" class="buttonFiltrarAtividade"
                                @click="() => this.exibirModalFiltros = false">Cancelar</v-btn>
                                </div>
                                </div>
               </div>
    </v-dialog>

                            <div style="overflow:auto;" >
        
                                        <div>
                                          <div style="display:flex; flex-direction: column;">
                                                <div class="containerBtnNovasPrioridades">
                                                            <v-btn small elevation="" color="info" 

                                                            @click="novaAtividade()">Adicionar novas atividades</v-btn>
                                                          
                                                            <v-select style="margin-left: 20px;" color v-model="filtroAtividades" label="Selecionar Atividade" :items="listaFiltrosAtividades"
                                                                @change="this.concluirFiltroAtividades" outlined return-object hide-details dense>
                                                            </v-select>
                                                    <div style="display:flex;">
                                                      
                                                  <v-text-field type="date" style="margin-left: 20px;" label=""  v-model="dtinicialconclusao" outlined return-object hide-details dense>
                                                  </v-text-field>
                                      
                                         
                                                  <v-text-field type="date" style="margin-left: 20px;" label=""  v-model="dtfinalconclusao" outlined return-object hide-details dense>
                                                  </v-text-field>
                                 
                                                      <v-btn   color="info" style="margin-left: 20px;"  @click="() => filtrarAtividadesPorData()">Buscar</v-btn>
                 
                                                    </div>
                                                
                                                    <v-col>
                                          </v-col>

                                                </div>


                                          </div>
                                          <div class="divFiltrosAtividades">
                                            <input type="text" class="inputFiltroAtividades" :placeholder="radioSelecionadoFiltro" @keyup="atividadesTabelaFiltro" v-model="textoInputFiltroAtividades"/>
                                            <div class="divRadiosFiltrosAtividades">
                                              <label class="labelRadio">Título</label>
                                              <input type="radio" v-model="radioSelecionadoFiltro" class="RadioTitulo" checked name="fav_language" value="Título">
                                              <label  class="labelRadio" for="css">Id</label>
                                              <input type="radio" v-model="radioSelecionadoFiltro" name="fav_language" value="Id">
                                            </div>
                                          </div>
                                             <v-container class="" style="margin-top:0px;padding-top:0px;">

                                                <v-row>
                                                  <v-col cols="6" md="3">
                                                    <p v-show="exibirTituloAtribuida"> <strong>Atividades atribuídas a {{usuario ? usuario.nome : 'Carregando...'}}</strong></p>
                                                   
                                                  </v-col>
                                                  
                                              
                                                </v-row>
                                              </v-container>
                                        </div>
                                        <v-container class="" style="padding-left:0px;margin-left:0px;padding-right:0px!important;margin-right:0px!important;width:100%!important;min-width:100%;margin-top:0px;padding-top:0px;">
                                         
                                            <div style="display:flex;flex-direction:column;margin-top:0px;">

                                                        <v-data-table  :items-per-page="15"  v-show="exibirTableAtribuidas" :headers="verModoAtribuida == 'concluidas'? headersConcluidas:headers" :items="listadeatividadesatribuidas" class="tete" >

                                                          
                                                          <template v-slot:[`item.dt_entrega`]="{ item }">
                                                            <div>
                                                              <div>{{item.dt_entrega | formatarData}}</div>
                                                            </div>
                                                          </template>
                                                          
                                                          <template v-slot:[`item.key`]="{ item }">
                                                                    <div>
                                                                        <div><p>{{item.secundaria ? `Sub-${item.id_atividade_princial}` : item.id}}</p></div>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:[`item.tipo`]="{ item }">
                                                                    <div> 
                                                                        <img class="imgBug" v-if="item.tipo === 'Bug'" src="/Prioridades/iconeBug.png" title="Bug"/>
                                                                        <img class="imagemFeature" v-if="item.tipo === 'Nova Funcionalidade'" src="/Prioridades/iconFeature.png" title="Nova funcionalidade"/>
                                                                        <img v-if="item.tipo === null" class="imgPrioridadeNull" src="/Prioridades/iconeNull.png"/>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.prioridade`]="{ item }">
                                                                    <div> 
                                                                        <button v-if="item.prioridade" type="button" @click="verPrioridadeAtividade(item.prioridade)">
                                                                            <img class="imgPrioridade" src="/Prioridades/4965408.png" :title="item.prioridade"/>
                                                                        </button>
                                                                        <img v-else class="imgPrioridadeNull" src="/Prioridades/iconeNull.png" title="Nenhuma"/>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.actions`]="{item}" >
                                                                        <div style="display:flex;" v-if="!item.fl_concluida">
                                                                            <v-btn small elevation="" @click="removerUsuario(item)" color="error">
                                                                                 <v-icon color="">mdi-arrow-left-circle</v-icon>
                                                                            </v-btn>
                                                                            <v-btn small elevation="" class="ml-2" @click="addHorasTrabalhadas(item)">
                                                                                <img class="addHoras" src="/Prioridades/addHoras.png" alt="imgAddHora" />
                                                                            </v-btn>
                                                                            <v-btn small elevation="" class="ml-2" @click="setExibirModalObservacao(item)" color="info">
                                                                                <v-tooltip bottom>
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
                                                                                    </template>
                                                                                    <span>{{item.ultimaobs?item.ultimaobs:`sem observações`}}</span>
                                                                                </v-tooltip>
                                                                            </v-btn>

                                                                            <v-btn small elevation="" class="ml-2" @click="marcarConcluida(item)" color="success">
                                                                                <v-icon color="">mdi-check</v-icon>
                                                                            </v-btn>
                                                                            
                                                                            <v-btn v-if="mayChange(item)" small class="ml-2" style="text-align:center;"
                                                                                @click="editarAtividade(item)"
                                                                            >
                                                                                <v-icon color="green" title="Editar">
                                                                                    mdi-pencil
                                                                                </v-icon>
                                                                            </v-btn>
                                                                            
                                                                        </div>
                                                                        <div style="display:flex;" v-else>
                                                                            <v-icon color="">mdi-check</v-icon>
                                                                            <v-btn v-if="item.secundaria" small elevation="" class="ml-2" 
                                                                                @click="exibirAnexo(item.imagem)"
                                                                            >
                                                                                <img class="anexoIcone" src="/Prioridades/anexo.png" alt="icone anexo" />
                                                                            </v-btn>
                                                                        </div>
                                                                </template>
                                                                <template v-slot:[`item.dt_concluida`]="{ item }">
                                                                    {{item.dt_concluida | formatarDataHora}}
                                                                </template>
                                                                <template v-slot:[`item.id`]="{ item }">
                                                                    <div style="display:flex">
                                                                    {{item.id}}
                                                                    <div :title="item.id_obsercao_fl_impedimento">
                                                                        <v-icon  v-if="item.fl_impedimento" color="error">mdi-flag</v-icon>
                                                                    </div>
                                                                    </div>
                                                                </template>
                                                               <template v-slot:[`item.titulo`]="{ item }">
                                                                    <div>
                                                                        <div><h4 style="color:#666666;">{{item.titulo}}</h4></div>
                                                                        <div>{{item.descricao}}</div>
                                                                        
                                                                    </div>
                                                                </template>

                                                    
                                                                <template v-slot:[`item.dt_entrega`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.dt_entrega | formatarData}}</div>
                                                                    </div>
                                                                </template>
                                                        </v-data-table>

                                                        <VotacaoModal v-if="exibirVotacao" :exibirVotacao="exibirVotacao" :atividade="atividadeParaVotar" :sala="sala"
                                                        :descricao="descricaoSala" :idAtividade="idAtividade"
                                                        @fecharModal="() => this.exibirVotacao = false"/>  

                                                        <v-data-table :items-per-page="5" v-show="esconderTabelaAtividades" :headers="headers" :items="listadeatividades " class="elevation-1" >
                                                                <template v-slot:[`item.actions`]="{item}">
                                                                    <div style="display:flex;" v-if="!item.fl_concluida">
                                                                        <v-btn small elevation="" @click="selecionarUsuarioParaAtribuir(item)" color="info">
                                                                            <v-icon color="">mdi-arrow-right-circle</v-icon>
                                                                        </v-btn>
                                                                        <v-btn v-if="mayChange(item)" small class="ml-2" style="text-align:center;"
                                                                            @click="editarAtividade(item)"
                                                                        >
                                                                            <v-icon color="green" title="Editar">
                                                                                mdi-pencil
                                                                            </v-icon>
                                                                        </v-btn>
                                                                        <v-btn small class="ml-2" style="text-align:center;"
                                                                            @click="votarAtividade(item)"
                                                                        >
                                                                           Votar Atividade
                                                                        </v-btn>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.titulo`]="{ item }">
                                                                    <div>
                                                                        <div><h4 style="color:#666666;">{{item.titulo}}</h4></div>
                                                                        <div>{{item.descricao}}</div>
                                                                        
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.dt_entrega`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.dt_entrega | formatarData}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.tipo`]="{ item }">
                                                                    <div> 
                                                                        <img class="imgBug" v-if="item.tipo === 'Bug'" src="/Prioridades/iconeBug.png" title="Bug"/>
                                                                        <img class="imagemFeature" v-if="item.tipo === 'Nova Funcionalidade'" src="/Prioridades/iconFeature.png" title="Nova funcionalidade"/>
                                                                        <img v-if="item.tipo === null" class="imgPrioridadeNull" src="/Prioridades/iconeNull.png"/>
                                                                        <img v-if="item.tipo === 'Meets'" class="imgmeet" src="/Prioridades/meet.png" title="Meets"/>
                                                                        <img v-if="item.tipo === 'Testes'" class="imgteste" src="/Prioridades/teste.png"
                                                                        title="Testes"
                                                                        />
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.prioridade`]="{ item }">
                                                                    <div> 
                                                                        <button v-if="item.prioridade" type="button" @click="verPrioridadeAtividade(item.prioridade)">
                                                                            <img class="imgPrioridade" src="/Prioridades/4965408.png" :title="item.prioridade"/>
                                                                        </button>
                                                                        <img v-else class="imgPrioridadeNull" src="/Prioridades/iconeNull.png" title="Nenhuma"/>
                                                                    </div>
                                                                </template>

                                                        </v-data-table>
                                                    
                                                
                                            </div>
                                           
                                        </v-container>
                                     
                              </div>
                          </v-card-text>
                          <!-- <v-card-text>
                              <div style="text-align:end;">
                                  <h4>
                                  Total:{{listaClienteQtdePorStatus.totalClientes}}
                                  </h4>
                              </div>
                          </v-card-text> -->
                        </v-card>
                    </v-col>
                </v-row>
              </v-container>
        </div>
        <v-dialog v-model="exibirModalAddHoras" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>

                      <v-col cols="12" md="12">
                            <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="criarHorasTrabalhadas.titulo" outlined return-object hide-details dense>
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                            <v-textarea label="Descrição"  v-model="criarHorasTrabalhadas.descricao" outlined return-object hide-details dense >
                            </v-textarea> 
                            
                      </v-col>

                  
                      <v-col cols="4">
                            <v-text-field type="number" label="Horas trabalhadas" 
                                v-model="criarHorasTrabalhadas.horas_trabalhadas" outlined dense hide-details 
                               
                            >
                            </v-text-field>
                      </v-col>

                      <v-col cols="12" md="12">
                        <input id="file-upload" type="file" accept="image/*"  @change="onFileChange" />
                            
                      </v-col>

                      <v-col cols="12" md="12">
                        <img v-if="imgSrc"
                    :src="'data:image.png;base64,' + imgSrc"
                    class="imgUpload"
                    />
                            
                      </v-col>
                      
                    
                      <v-col cols="12" md="12">
                           
                              <v-row>

                                  
                                  <v-col>
                                      <v-btn elevation="" @click="() => finalizarAddHorasTrabalhadas()" color="info" block>  Registrar</v-btn> 
                                    </v-col>
                                    <v-col>
                                        <v-btn elevation="" @click="() => exibirModalAddHoras=false" color="error" block> Cancelar</v-btn> 
                                    </v-col>
                            </v-row>

                      </v-col>

                    </v-row>
                   
                  </v-container>
              </div>
              
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalAnexo" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
            
                 

                      <v-col cols="12" md="12">
                        <img v-if="imgSrc"
                    :src="'data:image.png;base64,' + imgSrc"
                    class="imgUpload"
                    />           
                      </v-col>
                      <v-col>
                                        <v-btn elevation="" @click="() => exibirModalAnexo=false" color="error" block> Fechar</v-btn> 
                                    </v-col>
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalCriarAtividade" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>

                      <v-col cols="12" md="12">
                            <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="atividadeCriar.titulo" outlined return-object hide-details dense>
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                            <v-textarea label="Descrição"  v-model="atividadeCriar.descricao" outlined return-object hide-details dense >
                            </v-textarea> 
                            
                      </v-col>

                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                        >
                    
                            <v-select
                                :items="selectPrioridade"
                                item-text="titulo"
                                item-value="id"
                                label="Selecionar Prioridade"
                                v-model="atividadeCriar.prioridade"
                            ></v-select>

                        </v-col>

                      <v-col
                        class="d-flex"
                        cols="12"
                        sm="6"
                        >
                        
                        <v-select
                            :items="tipos"
                            label="Tipo"
                            v-model="atividadeCriar.tipo"
                        ></v-select>
                        </v-col>

                
                      <v-col cols="4">
                            <v-text-field type="date" label="Data para entrega" 
                                v-model="diaselecionado" outlined return-object hide-details dense 
                               
                            >
                            </v-text-field>
                      </v-col>
                      <v-col cols="4">
                            <v-text-field type="number" label="Horas estimadas" 
                                v-model="atividadeCriar.horas_estimadas" outlined dense hide-details 
                               
                            >
                            </v-text-field>
                      </v-col>
                      <v-col>
                                                <v-select color v-model="usuarioAtribuirAtividade" label="Usuario Implantador" item-text="nome" item-value="id" :items="listadeusuarios"
                                                    @change="buscarAtribuidasPendentes({idusuario:usuario.id})" outlined return-object hide-details dense>
                                                </v-select>
                                          </v-col>
                      <v-col cols="12" md="12">
                           
                              <v-row>

                                  
                                  <v-col>
                                      <v-btn elevation="" @click="() => registrarAtividade()" color="info" block>Registrar</v-btn> 
                                    </v-col>
                                    <v-col>
                                        <v-btn elevation="" @click="() => exibirModalCriarAtividade=false" color="error" block> Cancelar</v-btn> 
                                    </v-col>
                            </v-row>

                      </v-col>

                    </v-row>
                  </v-container>
              </div>
              
          </div>
    </v-dialog>

    <v-dialog v-model="modalNotificar.exibir" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>
                        <v-col>
                            <v-select
                                color 
                                v-model="modalNotificar.usuarioASerEnviado" 
                                label="Quem você deseseja notificar?" 
                                item-text="nome" 
                                item-value="id" 
                                :items="listadeusuarios"
                                outlined 
                                return-object 
                                chips
                                multiple
                            >
                            </v-select>
                        </v-col>

                      <!-- <v-col cols="12">
                            <v-textarea 
                                label="Deseja escrever uma mensagem personalizada?"
                                v-model="modalNotificar.descricao" 
                                outlined 
                                return-object 
                                hide-details 
                            >
                            </v-textarea> 
                      </v-col> -->
                      
                        
                      <v-col cols="12">
                           
                        <v-row>
                            <v-col>
                                <v-btn elevation="" @click="() => notificarUsuario()" color="info" block>Notificar</v-btn> 
                            </v-col>
                            <v-col>
                                <v-btn elevation="" @click="() => modalNotificar.exibir=false" color="error" block> Cancelar</v-btn> 
                            </v-col>
                        </v-row>

                      </v-col>

                    </v-row>
                  </v-container>
              </div>
              
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalDesejaVincularAtividade" width="80vw" max-width="500px">
                    <div class="containerDetalhesPrioridades" style="background-color:white">
                        
                                <h3>Deseja atribuir essa atividade a um usuário?</h3>
                                <button class="cancelarDeletarPrioridade" type="button" @click="() => {
                                    this.exibirModalDesejaVincularAtividade = false;
                                    this.exibirModalPrioridadeAtribuir = true;
                                }">Continuar</button>
                                <button class="cancelarDeletarPrioridade" type="button" @click="() => exibirModalDesejaVincularAtividade=false">Cancelar</button>
                    </div>
                </v-dialog>

    <v-dialog v-model="exibirModalPrioridadeAtividade" width="80vw" max-width="500px">
          <div class="containerDetalhesPrioridades" style="background-color:white">
             
                    <h2>{{Titulo}}</h2>
                    <p>{{descricao}}</p>
                    <button class="btnFecharDetalhePrioridade" type="button" @click="() => exibirModalPrioridadeAtividade=false">Fechar</button>
         
              
          </div>
    </v-dialog>

    <v-dialog v-model="exibirModalPrioridadeAtribuir" width="80vw" max-width="500px">
          <div class="containerDetalhesPrioridades" style="background-color:white">
             
                                    <v-col>
                                                <v-select color v-model="usuarioAtribuirAtividade" label="Usuario Implantador" item-text="nome" item-value="id" :items="listadeusuarios"
                                                    @change="buscarAtribuidasPendentes({idusuario:usuario.id})" outlined return-object hide-details dense>
                                                </v-select>
                                          </v-col>
                    <button class="btnFecharDetalhePrioridade" type="button" @click="handleAtribuirUsuario">Atribuir</button>
                    <button class="btnFecharDetalhePrioridade" type="button" @click="() => exibirModalPrioridadeAtribuir = false">Fechar</button>
          </div>
    </v-dialog>


    <v-dialog v-model="exibirModalObservacoes" width="80vw" max-width="800px">
        <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>

                      <v-col v-if="itemAtribuidoParaObsevacao" cols="12" md="12">
                            <v-container class="">
                              <v-row>
                                <v-col  cols="6" md="6" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                    <v-btn v-if="!this.itemAtribuidoParaObsevacao.fl_impedimento && !verObservacoes" small elevation="" color="" @click="setImpedimentoObservacao(true)">Registrar Impedimento</v-btn>
                                    <v-btn v-if="this.itemAtribuidoParaObsevacao.fl_impedimento && !verObservacoes" small elevation=""  color="error" @click="setImpedimentoObservacao(false)">Remover Impedimento</v-btn>
                                </v-col>
                                <v-col cols="6" md="6" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;text-align:end;" >
                                    <a v-if="!verObservacoes" @click="setVerObservacoes(true)">Ver Observações</a>
                                    <a v-if="verObservacoes" @click="setVerObservacoes(false)">Registrar Obsevação</a>
                                </v-col>
                              </v-row>
                            </v-container>
                                
                      </v-col>

                      <v-col cols="12" md="12" v-if="verObservacoes">
                            
                                <v-row>

                                    <v-col >
                                        <v-data-table :headers="headersobservacoes" :items="listadeobservacoes" class="elevation-1" >
                                            <template v-slot:[`item.id`]="{ item }">
                                                <div style="display:flex">
                                                {{item.id}}
                                                <div :title="item.fl_observacao_impedimento">
                                                    <v-icon  v-if="item.fl_observacao_impedimento" color="error">mdi-flag</v-icon>
                                                </div>
                                                </div>
                                            </template>
                                            <template v-slot:[`item.data_criacao`]="{ item }">
                                                {{item.data_criacao | formatarDataHora}}
                                            </template>
                                        </v-data-table>
                                    </v-col>

                                </v-row>
                            
                        </v-col>
                    
                        <v-col v-if="itemAtribuidoParaObsevacao  && !verObservacoes" cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;">
                                <strong style="margin-right:10px;">Id:</strong>{{itemAtribuidoParaObsevacao.id}}
                        </v-col>
                        <v-col v-if="itemAtribuidoParaObsevacao && !verObservacoes" cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;">
                                <strong style="margin-right:10px;">Título:</strong>{{itemAtribuidoParaObsevacao.titulo}}
                        </v-col>
                        <v-col cols="12" md="12" v-if="itemAtribuidoParaObsevacao && !verObservacoes">
                            <v-row>
                                <v-col cols="6">
                                    <v-checkbox
                                        v-model="avisarPorEmail"
                                        :label="`Avisar por email:`"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row v-if="avisarPorEmail" id="email-options">
                                <v-col cols="4">
                                    <v-text-field 
                                        v-model="emailEnvio" id="email-envio" type="email"
                                        label="Email" outlined dense hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="date" label="Data para envio"  
                                        v-model="dataEnvio" outlined dense hide-details >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field type="time" label="Hora para envio"  
                                        v-model="horaEnvio" outlined dense hide-details >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="12" v-if="!verObservacoes">
                                <v-textarea label="Observação"  v-model="textoNovaObservacao" outlined return-object hide-details dense >
                                </v-textarea> 
                                
                        </v-col>
                        <v-col cols="12" md="12">
                            
                                <v-row>

                                    
                                    <v-col>
                                        <v-btn v-if="!verObservacoes" elevation="" @click="() => registrarObsevacao()" color="info" block>  Registrar</v-btn> 
                                    </v-col>
                                    <v-col>
                                        <v-btn elevation="" @click="() => {exibirModalObservacoes=false;verObservacoes=false;listadeobservacoes=[];}" color="error" block> Cancelar</v-btn> 
                                    </v-col>
                                </v-row>
                            
                        </v-col>      

                    </v-row>
                  </v-container>
              </div>
              
        </div>
    </v-dialog>


    <v-dialog v-model="exibirImplantacoes" width="80vw" max-width="1000px">
        <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
               <v-container class="" style="">
                  <v-row>
                    <v-col cols="12" md="3">
                        <v-select v-model="implantacao_sistema" label="Sistema" item-text="nome" item-value="id" :items="listadesistema" outlined return-object hide-details dense >
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select color v-model="implantacao_cliente" label="Cliente" item-text="nome" item-value="id" :items="clientes" outlined return-object hide-details dense>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select style="" color v-model="implantacao_status" label="Status" item-text="nome" item-value="id" :items="listadestatus" outlined return-object hide-details dense>
                        </v-select>
                    </v-col>
                    <!-- <v-col cols="12" md="2">
                        <v-select v-model="implantacao_statuschecklist" label="CheckList" item-text="nome" item-value="id" :items="listastatuschecklist" outlined return-object hide-details dense>
                        </v-select>
                    </v-col> -->
                    <v-col cols="12" md="3">
                            <v-btn small elevation="" color="info" large @click="() => requisitarImplantacoes()">
                                 <v-icon color="">mdi-magnify</v-icon>
                            </v-btn>
                        
                    </v-col>
                  </v-row>
                </v-container>   
                 <v-container class="">
                    <v-row>
                        <v-col>
                            <v-data-table :headers="headersimplantacoes" :items="implantacoes" class="elevation-1" >
                                <template v-slot:[`item.id`]="{ item }">
                                    <div style="display:flex">
                                    {{item.id}}
                                    <div :title="item.fl_observacao_impedimento">
                                        <v-icon  v-if="item.fl_observacao_impedimento" color="error">mdi-flag</v-icon>
                                    </div>
                                    </div>
                                </template>
                                <template v-slot:[`item.nomecliente`]="{ item }">
                                    <div style="display:flex;flex-direction:column;" >
                                        <div><strong style="color:#767676;">{{item.nomecliente}}</strong></div>
                                        <div style="color:#2d7ab9;">
                                             <v-icon color="" style="font-size:10px;color:#c8ddef;">mdi-arrow-right-bottom</v-icon>
                                            
                                            {{item.nomeetapa}}
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:[`item.nomesistema`]="{ item }">
                                    <div style="display:flex;flex-direction:column;" >
                                        <div>{{item.nomesistema}}</div>
                                        <div>
                                            <p :style="(item.concluida ?'color:green':'color:gray')">{{item.concluida ? 'Concluido' :  (item.datainicio ?'Iniciado':'-')}}</p>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:[`item.acao`]="{ item }">
                                    <v-btn small elevation="" @click="criarAtividadeApartirDaImplantacao(item)" color="info">
                                        <v-icon color="">mdi-plus-circle</v-icon>
                                    </v-btn>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
        </div>
    </v-dialog>


    <v-dialog v-model="exibirModalCriarPrioridade" width="80vw" max-width="600px">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
              <div>
                 <v-container class="pa-8">
                    <v-row>

                      <v-col cols="12" md="12">
                            <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="prioridadeCriar.titulo" outlined return-object hide-details dense>
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                            <v-textarea label="Descrição"  v-model="prioridadeCriar.descricao" outlined return-object hide-details dense >
                            </v-textarea> 
                            
                      </v-col>
                      <v-col cols="4">
                            <v-text-field type="date" label="Data de criação" 
                                v-model="prioridadeCriar.data_criacao" outlined dense hide-details 
                                :min="dataHoje()"
                            >
                            </v-text-field>
                      </v-col>

                      <v-col cols="4">
                            <v-text-field type="date" label="Data para conclusão" 
                                v-model="prioridadeCriar.data_conclusao_estimada" outlined dense hide-details 
                                :min="dataHoje()"
                            >
                            </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                           
                              <v-row>

                                  
                                  <v-col>
                                      <v-btn elevation="" @click="() => cadastrarPrioridade()" color="info" block>  Registrar</v-btn> 
                                    </v-col>
                                    <v-col>
                                        <v-btn elevation="" @click="() => exibirModalCriarPrioridade=false" color="error" block> Cancelar</v-btn> 
                                    </v-col>
                            </v-row>

                      </v-col>

                    </v-row>
                  </v-container>
              </div>
              
          </div>
    </v-dialog>
    </v-container>

    
</template>

<script>



import UsuarioServico from '@/services/usuario.service.js';
import AtividadeServico from '@/services/atividade.service.js';
import SistemaServico from '@/services/sistema.service.js';
import ClienteServico from '@/services/cliente.service.js';
import ImplantacaoServico from '@/services/implantacao.service.js';
import dayjs from 'dayjs';
import Prioridades from '@/services/prioridades.service.js';
import {alertar} from '@/services/notificacao.service.js';
import exportarTabela from '../util/exportarTabelas';
import serialize from '../services/serializar.js';
import votacao from '../services/votacao.service';
import VotacaoModal from './votacao.vue';

export default {
    name: 'Prioridades',
  components: {
    VotacaoModal
  },
  data(){

    return{
            radioSelecionadoFiltro: "Título",
            textoInputFiltroAtividades: null,
            listadeprojetosFiltro: null,
            exibirVotacao: false,
            sala: null,
            atividadeParaVotar: null,
            descricaoSala: null,
            radioSelecionadoFiltroProjetos: 'Título',
            textoInputFiltroProjetos: null,
            avisarPorEmail: false,
            exibirModalExportarParaExcel: false,
            tabelas: ['Atividades', 'Projetos disponíveis'],
            tabelaSelecionada: '',
            emailEnvio: localStorage.getItem("email"), // email do usuario corrente small
            dataEnvio: dayjs().format('YYYY-MM-DD'),
            horaEnvio: '08:00',
            implantacao_sistema:{id:0,nome:'Todos'},
            implantacao_cliente:{id:0,nome:'Todos'},
            implantacao_status:{id:0,nome:'Todos'},
            implantacao_statuschecklist:{id:0,nome:'Todos'},
            exibirModalEditarPrioridade: false,
            exibirModalFiltrosRelatoriosAtividades: false,
            exibirModalAddHoras: false,
            atividadesExportar: null,
            projetosExportar: null,
            exibirModalAnexo: false,
            prioridadeEditar: {
                id: null,
                titulo: null,
                descricao: null,
                dataCriacao: null,
                dataConclusao: null,
                idusuario: null,
            },
            listadesistema:[],
            clientes:[],
            Titulo: '',
            descricao: '',
            outlinedBtnProjetos: false,
            outlinedBtnAtividadesDisponiveis: true,
            outlinedBtnAtividadesAtribuidas: true,
            prioridades:[],
            selectPrioridadeModel: [],
            atividadePrioridade: '',
            exibirModalPrioridadeAtribuir: false,
            imgExibir: null,
            exibirModalDeletarPrioridade: false,
            verAtividade:true,
            esconderTabelaAtividades:false,
            verPrioridadesAtribuidas:true,
            filtroAtividades: 'Pendentes',
            listadeatividadesatribuidasparafiltro: null,
            IditemHorasTrabalhadas: null,
            tipos: ['Bug', 'Nova Funcionalidade', 'Testes', 'Meets'],
            exibirModalPrioridadeAtividade: false,
            selectPrioridade: [],
            prioridadeSelecionada: '',
            modalPrioridadesAtribuidas: false,
            modalAtividadesEPrioridades: true,
            idAtividade: null,
            tipoSelecionado: '',
            exibirModalFiltros: false,
            exibirTableAtribuidas: true,
            implantacoes:[],
            headers2: [
        {
          text: 'id',with:'15%',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Titulo', value: 'titulo',width:'20%' },
        { text: 'Descrição ', value: 'descricao',width:'20%' },
        { text: 'Qtde Atividades', value: 'qtdeatividades',width:'20%' },
        { text: 'Qtde Concluidas', value: 'qtdeconcluidas',width:'20%' },
        { text: 'Qtde Atrasadas', value: 'qtdeatrasada',width:'20%' },
        { text: 'Percentual Executada;', value: 'percentualconcluido',width:'20%' },
        { text: 'Data de criação', value: 'data_criacao' },
        { text: 'Data de conclusão estimada', value: 'data_conclusao_estimada' },
        { text: 'Ações', value: 'acoes' },
      ],
            listadestatus:[
                                {id:0,nome:'Todos'},
                                {id:1,nome:'Não Iniciado'},
                                {id:2,nome:'Iniciado'},
                                {id:3,nome:'Concluido'},
                                {id:4,nome:'Atrasado'}
                            ],
            listastatuschecklist:[
                                {id:0,nome:'Todos'},
                                {id:1,nome:'Pendente'},
                                {id:2,nome:'Concluido'}
                            ],
            exibirImplantacoes:false,
            verObservacoes:false,
            verModoAtribuida:'concluidas',
            textoNovaObservacao:null,
            exibirModalObservacoes:false,
            itemAtribuidoParaObsevacao:null,
            exibirModalDesejaVincularAtividade: false,
            dataEstimativa: {
              data_inicial: null,
              data_final: null,
            },
            dataConclusao: {
              data_inicial: null,
              data_final: null,
            },
            dtinicialconclusao:dayjs().format('YYYY-MM-DD'),
            dtfinalconclusao:dayjs().format('YYYY-MM-DD'),
            exibirModalCriarAtividade:false,
            modalNotificar: {
                exibir: false,
                usuarioASerEnviado: [],
                descricao: null,
                mensagemPersonalizada: null,
                atividade: "",
            },
            exibirModalCriarPrioridade:false,
            listadeusuarios:[],
            diaselecionado: null,
            usuario:null,
            imgSrc: null,
            Atribuidas: null,
            exibirTituloAtribuida: false,
            listaFiltrosAtividades: ['Pendentes', 'Conluídas', 'Disponíveis'],
            status: null,
            listadeobservacoes:[],
            listadeatividades:[],
            fitroListaDeAtividades: [],
            usuarioAtribuirAtividade: null,
            listadeatividadesatribuidas:[],
            atividadeatribuida:null,
            atividadeState: null,
            atividade:null,
            atividadeCriar:{ id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null, },
            criarHorasTrabalhadas: {descricao: null, titulo:null, horas_trabalhadas: null},
            prioridadeCriar:{id:null,titulo:null,descricao:null,data_criacao:null,data_conclusao_estimada:null},
            headers:[
                { text: "Id", value: "id", width:'15%'},
                { text: "Titulo", value: "titulo", width:'25%'},
                { text: "Entrega", value: "dt_entrega", width:'25%'},
                { text: "Tipo", value: "tipo", width:'15%'},
                { text: "Prioridade", value: "prioridade", width:'25%'},
                { text: "Horas Estimadas", value: "horas_estimadas", width:'25%'},
                { text: "Horas Trabalhadas", value: "horas_trabalhadas", width:'25%'},
                // { text: "Acões", value: "id", width:'20%'},
                { text: "Ações", value: "actions", width: "120px", sortable: false},
            ],
            headersConcluidas:[
                { text: "id", value: "key", width:'15%'},
                { text: "Titulo", value: "titulo", width:'50%'},
                { text: "Data", value: "dt_concluida", width:'30%'},
                { text: "Horas Trabalhadas", value: "horas_trabalhadas", width:'30%'},
                { text: "Ações", value: "actions", width: "10%", sortable: false},
            ],
            headersobservacoes:[
                { text: "ID", value: "id", width:'15%'},
                { text: "Data", value: "data_criacao", width:'30%'},
                { text: "Observação", value: "observacao", width:'50%'},
            ],
            headersimplantacoes:[
                { text: "ID", value: "id", width:'15%'},
                { text: "Cliente", value: "nomecliente", width:'50%'},
                { text: "Sistema", value: "nomesistema", width:'30%'},
                { text: "Ação", value: "acao", width:'10%'},
            ]
    }
  },
  
  methods:{
        fecharModalCriarAtividade(){
            this.exibirModalCriarAtividade=false
        },
        async notificarUsuario() {
            let dados = {
                atividade: this.modalNotificar.atividade.titulo,
                emissor: this.usuario,
                usuariosaNotificar: this.modalNotificar.usuarioASerEnviado,
            };

            await AtividadeServico.enviarNotificacao(dados).then(() => {
                this.modalNotificar.exibir = false;
                this.modalNotificar.usuarioASerEnviado = [];
            })
            
        },
        async criarAtividadeApartirDaImplantacao(implantacao){

       
                if(implantacao.id){
                
                let dados = {
                    idimplantacao:implantacao.id,
                    titulo:`Implantação ${implantacao.nomesistema}: ${implantacao.nomecliente}`,
                    descricao:'',
                    dt_criacao:dayjs().format()
                }

                await AtividadeServico.criar(dados).then(async (resp) => { 
                    console.log("registrarAtividade resp ",resp)
                    this.atividadeCriar={ id:null, titulo:null, descricao:null, idimplantacao:null, dt_entrega: null, idusuario: null}
                    await this.requisitarAtividadesDisponiveis() 
                    await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                }).catch(error=>{
                    alert('Não foi Possível Registrar!')
                    console.log(error)
                    this.exibirModalCriarAtividade=false
                })
                }else{
                    alert("Implantação não selecionada!")    
                }

        },

        async requisitarImplantacoes(){

            let dados = {
                idsistema:this.implantacao_sistema.id,
                idcliente:this.implantacao_cliente.id,
                status:this.implantacao_status.id,
            }

            if (dados.idsistema == 0){ dados.idsistema = null }
            if (dados.idcliente == 0){ dados.idcliente = null }
            if (dados.status == 0){ dados.status = null }

            const implantacoes = await ImplantacaoServico.buscar(dados).then(res => res.data)
            this.implantacoes = implantacoes
            
        },
        async requisitarClientes(){
            let lclientes = await ClienteServico.buscar().then(res => res.data)
            this.clientes = [{id:0,nome:'Todos'}]
            this.clientes = this.clientes.concat(lclientes)
        },
        async requisitarSistemas(){
            let sistemas = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
            this.listadesistema = [{id:0,nome:'Todos'}]
            this.listadesistema = this.listadesistema.concat(sistemas)
        },
        setVerObservacoes(status){

            if(status){
                this.requisitarObservacoes()
            }
            this.verObservacoes = status

        },
        async setVerImplantacoes(){
            await this.requisitarSistemas()
            await this.requisitarClientes()
            this.exibirImplantacoes = true
        },
        setPrioridadesAtividades(){
            if(this.esconderTabelaAtividades){        
                this.esconderTabelaAtividades = false;
                this.verPrioridadesAtribuidas = true;
            }else{
                this.esconderTabelaAtividades = true;
                this.verPrioridadesAtribuidas = false;
            } 
        },

        onFileChange(e) {
          console.log(serialize);
          serialize.serializarImagemNew(e).then(resp =>{ 
            this.imgSrc = resp.dados
            
            }).catch(error =>{
              console.log(error);
            })

          },

        setImpedimentoObservacao(status){
            this.itemAtribuidoParaObsevacao.fl_impedimento = status
            this.itemAtribuidoParaObsevacao.fl_impedimento_novo = status
        },
        setExibirModalObservacao(dados){
            this.itemAtribuidoParaObsevacao = dados
            this.exibirModalObservacoes = true
        },
        async buscarAtribuidasConcluidas(dados){
            this.verModoAtribuida = 'concluidas'
            this.listadeatividadesatribuidas = []
           const body = {idusuario:dados.idusuario,fl_concluida:'true',dtinicialconclusao:dados.dtinicial,dtfinalconclusao:dados.dtfinal}
           console.log(body)

            await AtividadeServico.buscarAtividadesAtribuidas(body)
            .then(res => {
                console.log(res.data);
                this.listadeatividadesatribuidas = res.data;
                this.listadeatividadesatribuidasparafiltro = res.data
            }).catch(error=>{
                alert(error)
            })
           
           
        },

        async concluirFiltroAtividades() {   
          console.log(this.filtroAtividades)
          if(this.filtroAtividades === 'Conluídas') {
            this.esconderTabelaAtividades = false; 
            this.exibirTituloAtribuida = true;  
            this.exibirTableAtribuidas = true;
            await this.buscarAtribuidasConcluidas({idusuario: this.usuario.id,dtinicial: this.dtinicialconclusao,dtfinal: this.dtfinalconclusao})
            this.exibirModalFiltros = false;
          } else if( this.filtroAtividades === 'Disponíveis') {
            this.exibirTableAtribuidas = false;
            this.esconderTabelaAtividades = true;
            this.exibirTituloAtribuida = false;
            this.exibirModalFiltros = false;
          } else {
            this.esconderTabelaAtividades = false;   
            this.exibirTableAtribuidas = true;
            this.exibirTituloAtribuida = true;
             this.exibirModalFiltros = false;
            await this.buscarAtribuidasPendentes({idusuario: this.usuario.id})
          }
        },

        async buscarAtribuidasPendentes(dados){
            this.verModoAtribuida = 'pendentes'
            this.listadeatividadesatribuidas = []

            await AtividadeServico.buscarAtividadesAtribuidas({idusuario:dados.idusuario,fl_concluida:'false'})
            .then(res => {
              console.log('atividades atrivuidas', res.data);
                this.listadeatividadesatribuidas = res.data;
                this.listadeatividadesatribuidasparafiltro = res.data;
            }).catch(error=>{
                alert(error)
            })
           
 
        },
        
        async requisitarUsuarios(){
            this.listadeusuarios = await UsuarioServico.buscar({tipo:1}).then(res => res.data)
            console.log(this.listadeusuarios)
            let indexUsuarioFiltrado = this.listadeusuarios.findIndex(x =>{ return x.id == localStorage.getItem('idusuario')})
            this.usuario = this.listadeusuarios[indexUsuarioFiltrado]
            if(!this.usuario){
                this.usuario = this.listadeusuarios[0]
            }
        },
        async requisitarObservacoes(){
            this.listadeobservacoes = await AtividadeServico.requisitarObservacoes({idatividade:this.itemAtribuidoParaObsevacao.id}).then(res => res.data)
        },
        async requisitarAtividadesDisponiveis(){
        
            await AtividadeServico.buscar({somenteDisponiveis:true})
            .then(res => {
                this.listadeatividades = res.data
                this.fitroListaDeAtividades = res.data;
                return res.data;
            }).catch(error=>{
                alert(error)
            })
           
            this.atividade = this.listadeatividades[0]
        },

       
        dataHoje() {
            return dayjs().format('YYYY-MM-DD');
        },
        mayChange(item) {
            return item.idusuario == localStorage.getItem('idusuario');
        },
        minhaPrioridade(item){
            return item.idusuario === localStorage.getItem('idusuario');
        },
        novaAtividade() {
            this.atividadeCriar={ id:null, titulo:null, descricao:null, idimplantacao:null, dt_entrega: dayjs().add(1,'day').format('YYYY-MM-DD'), idusuario: null }
            this.exibirModalCriarAtividade=true;
            document.getElementById('campo-titulo').focus();
        },
        novaPrioridade(){
            this.prioridadeCriar={
            id:null,titulo:null,descricao:null,data_criacao: dayjs().add(1,"day").format('YYYY-MM-DD'),data_conclusao_estimada:dayjs().add(1,"day").format('YYYY-MM-DD'),idusuario:null}
            this.exibirModalCriarPrioridade=true;
            document.getElementById("titulo").focus();
        },
        editarAtividade(atividade) {
          console.log(atividade);
            this.atividadeCriar = {...atividade};
            this.exibirModalCriarAtividade=true;
        },
        editarPrioridade(prioridade){
            this.prioridadeCriar = {...prioridade}
            this.exibirModalCriarPrioridade=true
        },

        addHorasTrabalhadas(item) {
          this.imgSrc = null;
          this.IditemHorasTrabalhadas = item.id
          this.exibirModalAddHoras = true;
        },

        atividadesTabelaFiltro() {
          if(this.filtroAtividades === 'Pendentes') {
            this.atividadesPendentesFiltro()
          } else if (this.filtroAtividades === 'Conluídas') {
            this.atividadesConcluídasFiltro()
          } else {
            this.atividadesDisponíveisFiltro()
          }
        },

        atividadesPendentesFiltro () {
          if(this.radioSelecionadoFiltro === 'Título') {
              const filtro = this.listadeatividadesatribuidasparafiltro.filter((atividade) => atividade.titulo.toLowerCase().includes(this.textoInputFiltroAtividades.toLowerCase()));
              this.listadeatividadesatribuidas = filtro;
            } else {
              const filtro = this.listadeatividadesatribuidasparafiltro.filter((atividade) => atividade.id.toString().includes(this.textoInputFiltroAtividades.toString()));
              this.listadeatividadesatribuidas = filtro;
            }
        },

        atividadesConcluídasFiltro () {
          if(this.radioSelecionadoFiltro === 'Título') {
              const filtro = this.listadeatividadesatribuidasparafiltro.filter((atividade) => atividade.titulo.toLowerCase().includes(this.textoInputFiltroAtividades.toLowerCase()));
              this.listadeatividadesatribuidas = filtro;
            } else {
              const filtro = this.listadeatividadesatribuidasparafiltro.filter((atividade) => atividade.id.toString().includes(this.textoInputFiltroAtividades.toString()));
              this.listadeatividadesatribuidas = filtro;
            }
        },

        atividadesDisponíveisFiltro () {
          if(this.radioSelecionadoFiltro === 'Título') {
              const filtro = this.fitroListaDeAtividades.filter((atividade) => atividade.titulo.toLowerCase().includes(this.textoInputFiltroAtividades.toLowerCase()));
              this.listadeatividades = filtro;
            } else {
              const filtro = this.fitroListaDeAtividades.filter((atividade) => atividade.id.toString().includes(this.textoInputFiltroAtividades.toString()));
              this.listadeatividades = filtro;
            }
        },

        projetosFiltros () {
          if(this.radioSelecionadoFiltroProjetos === 'Título') {
              const filtro = this.listadeprojetosFiltro.filter((projeto) => projeto.titulo.toLowerCase().includes(this.textoInputFiltroProjetos.toLowerCase()));
              this.prioridades = filtro;
            } else {
              const filtro = this.listadeprojetosFiltro.filter((atividade) => atividade.id.toString().includes(this.textoInputFiltroProjetos.toString()));
              this.prioridades = filtro;
            }
        },

        async finalizarAddHorasTrabalhadas () {
          const { descricao, titulo, horas_trabalhadas } = this.criarHorasTrabalhadas;
          const data = {
            descricao,
            titulo,
            horas_trabalhadas: horas_trabalhadas,
            id_atividade_princial: this.IditemHorasTrabalhadas,
            imagem: this.imgSrc,
            id_usuario: this.usuario.id
          }
          await AtividadeServico.addAtividadesSecundarias(data)
          .then( async () => {
            await this.requisitarAtividadesDisponiveis() 
            await this.buscarAtribuidasPendentes({idusuario:this.usuario.id,dtinicial:this.dtinicialconclusao,dtfinal:this.dtfinalconclusao})
            alertar('success', '=)', 'Horas trabalhadas registrada com sucesso!')
            this.exibirModalAddHoras = false;
            this.criarHorasTrabalhadas.descricao = null
            this.criarHorasTrabalhadas.titulo = null
            this.criarHorasTrabalhadas.horas_trabalhadas = null,
            this.imgSrc = null
            console.log('sucesso')
          })
          .catch((error) => {
            console.log(error)
          })
     
        },
       

        async filtrarAtividadesPorData() {
          console.log(this.filtroAtividades)
          if(this.filtroAtividades === 'Disponíveis') {
            await AtividadeServico.buscar({somenteDisponiveis:true, data_entrega: this.dtinicialconclusao, data_entrega_final: this.dtfinalconclusao})
            .then(res => {
                this.listadeatividades = res.data
            }).catch(error=>{
                alert(error)
            })
          } else if(this.filtroAtividades === 'Pendentes') {
            await AtividadeServico.buscarAtividadesAtribuidas({idusuario:this.usuario.id,fl_concluida:'false', dataEstimativaInicial:this.dtinicialconclusao,dataEstimativaFinal:this.dtfinalconclusao})
            .then(res => {
                this.listadeatividadesatribuidas = res.data
            }).catch(error=>{
                alert(error)
            })
          } else {
            await this.buscarAtribuidasConcluidas({idusuariregistrarAtividadeo: this.usuario.id,dtinicial: this.dtinicialconclusao,dtfinal: this.dtfinalconclusao, idusuario:this.usuario.id})
          }
        },

        async registrarAtividade(){
    
            if(this.atividadeCriar.titulo){
                this.atividadeCriar.idusuario = localStorage.getItem('idusuario');
                this.atividadeCriar.dt_entrega = this.diaselecionado;
                delete this.atividadeCriar.ultimaobs;
                console.log(this.atividadeCriar);
                await AtividadeServico.criar(this.atividadeCriar).then(async (resp) => { 
                    console.log("registrarAtividade resp ",resp)
                    alertar('success', '=)', 'Atividade registrada com sucesso!')
                    this.atividadeState = {id: resp.data[0]}
                    // this.exibirModalDesejaVincularAtividade = true;
                    this.exibirModalCriarAtividade=false 
                    this.atividadeCriar={ id:null, titulo:null, descricao:null, idimplantacao:null, dt_entrega: null, idusuario: null }
                    await this.requisitarAtividadesDisponiveis()
                    await this.registrarPrioridades()
                    await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                    await this.handleAtribuirUsuario();
                    
                }).catch(error=>{
                    alert('Não foi Possível Registrar!')
                    console.log(error)
                    this.exibirModalCriarAtividade=false
                })
            }else{
                alert("Titulo Obrigatório!")
            }
        },
        async cadastrarPrioridade(){
            if(this.prioridadeCriar.titulo && this.prioridadeCriar.descricao){
                this.prioridadeCriar.idusuario = localStorage.getItem("idusuario");
                delete this.prioridadeCriar.ultimaobs;
                await Prioridades.criarPrioridade(this.prioridadeCriar)
                .then(async(resp)=>{
                    console.log("CadastrarPrioridade resp",resp)
                    alertar('success', '=)', 'Prioridade cadastrada com sucesso!')
                    this.exibirModalCriarPrioridade=false
                    this.prioridadeCriar={id:null,titulo:null,descricao:null,data_conclusao:null,data_conclusao_estimada:null}
                    await this.registrarPrioridades();
                }).catch(error=>{
                    alert("Não foi possível Registrar a prioridade");
                    console.log(error);
                    this.exibirModalCriarPrioridade=false
                })      
            }else{
                alertar('basic', 'Atenção!', 'Preencha todos os campos necessários.')
            }

           
        },
        async updatePrioridade(){
            if(this.prioridadeCriar.titulo){
                this.prioridadeCriar.idusuario = localStorage.getItem("idusuario");
                delete this.prioridadeCriar.ultimaobs;
                await Prioridades.criarPrioridade(this.prioridadeCriar)
                .then(async(resp)=>{
                    console.log("CadastrarPrioridade resp",resp)
                    this.exibirModalCriarPrioridade=false
                    this.prioridadeCriar={id:null,titulo:null,descricao:null,data_conclusao:null,data_conclusao_estimada:null}
                    await this.atualizarPrioridade();
                }).catch(error=>{
                    alert("Não foi possível Registrar a prioridade");
                    console.log(error);
                    this.exibirModalCriarPrioridade=false
                })      
            }else{
                alert("Titulo Obrigatório");
            }

           
        },

        async votarAtividade(item) {
          console.log(item);
         
          const obj = {
        idusuario: localStorage.getItem('idusuario'),
        nome: localStorage.getItem('nome'),
        token: "b85a"
      }
      await votacao.entrar(obj)
      .then((res) => {
        this.sala = res.data;
        this.atividadeParaVotar = item.titulo;
        this.descricaoSala = item.descricao
        this.idAtividade = item.id;
        // localStorage.setItem('sala', JSON.stringify(res.data));
        // localStorage.setItem('atividade', JSON.stringify(item.titulo));
        // localStorage.setItem('descricao', JSON.stringify(item.descricao));
        // this.$router.push('/votacao')
        this.exibirVotacao = true;
      })
      .catch((error) => {
        console.log('erro ao criar sala', error)
      })
        },
      
        async selecionarUsuarioParaAtribuir(atividade){
               this.atividadeState = atividade;
               this.exibirModalPrioridadeAtribuir = true;
              
        },

        async handleAtribuirUsuario(){
                const atividade = this.atividadeState;
                if(this.usuarioAtribuirAtividade) {
                    atividade.usuario = this.usuarioAtribuirAtividade
                await AtividadeServico.atribuirUsuario(atividade).then(async (resp) => { 
                    console.log("atribuirUsuario resp ",resp)
                    alertar('success', '=)', `Usuario atribuido a ${this.usuarioAtribuirAtividade.nome} com sucesso!`)
                    this.exibirModalPrioridadeAtribuir = false;
                    this.usuarioAtribuirAtividade = null;
                    await this.requisitarAtividadesDisponiveis() ,
                    await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                }).catch(error=>{
                    alert('Não foi Possível Registrar!')
                    console.log(error)
                    this.exibirModalCriarAtividade=false
                })
                } else {
                    console.log('atividade não foi atribuida a nenhum usuário')
                }
                

        },

        exibirModais() {          
              if(this.filtroAtividades === 'Disponíveis') {
                this.esconderTabelaAtividades = true;
              }
                this.modalPrioridadesAtribuidas = true;
                this.modalAtividadesEPrioridades = false;
                this.outlinedBtnProjetos = true;
                this.outlinedBtnAtividadesAtribuidas = false;
                this.outlinedBtnAtividadesDisponiveis = true;
            
        },

        exibirModaisAtividadesDisponiveis() {
            this.modalPrioridadesAtribuidas = false;
            this.modalAtividadesEPrioridades = true;
            this.esconderTabelaAtividades = true,
            this.verPrioridadesAtribuidas = false; 

            this.outlinedBtnProjetos = true;
            this.outlinedBtnAtividadesAtribuidas = true;
            this.outlinedBtnAtividadesDisponiveis = false;
        },

        exibirModaisProjetos() {
            this.verPrioridadesAtribuidas = true;
            this.esconderTabelaAtividades = false;
            this.modalPrioridadesAtribuidas = false;
            this.modalAtividadesEPrioridades = true;

            this.outlinedBtnProjetos = false;
            this.outlinedBtnAtividadesAtribuidas = true;
            this.outlinedBtnAtividadesDisponiveis = true;
        },

        async removerUsuario(atividade){

                atividade.usuario = this.usuario
                await AtividadeServico.removerUsuario(atividade).then(async (resp) => { 
                    console.log("atribuirUsuario resp ",resp)
                    await this.requisitarAtividadesDisponiveis() ,
                    await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                }).catch(error=>{
                    alert('Não foi Possível Registrar!')
                    console.log(error)
                    this.exibirModalCriarAtividade=false
                })

        },
        async marcarConcluida(atividade){


            this.modalNotificar.atividade = atividade
            this.modalNotificar.exibir = true;

                atividade.usuario = this.usuario
                await AtividadeServico.marcarConcluida(atividade).then(async (resp) => { 
                    console.log("atribuirUsuario resp ",resp)
                    await this.requisitarAtividadesDisponiveis()
                    await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                }).catch(error=>{
                    alert('Não foi Possível Registrar!')
                    console.log(error)
                    this.exibirModalCriarAtividade=false
                })

        },

        verPrioridadeAtividade(prioridade) {
            
             const id = Number(prioridade);
            // prioridades
            const filterPrioridades = this.prioridades.filter((e) => e.id === id)[0]
            this.Titulo = filterPrioridades.titulo;
            this.descricao = filterPrioridades.descricao;
            this.exibirModalPrioridadeAtividade = true;
        },

        async registrarObsevacao(){

                let dados ={
                    idatividade:this.itemAtribuidoParaObsevacao.id,
                    observacao:this.textoNovaObservacao,
                    avisarporemail: this.avisarPorEmail,
                    emailenvio: this.emailEnvio,
                    datahoraenvio: `${this.dataEnvio} ${this.horaEnvio}`
                }
                if(this.itemAtribuidoParaObsevacao.fl_impedimento_novo === true){
                    dados.fl_impedimento = true
                }else{
                    if(this.itemAtribuidoParaObsevacao.fl_impedimento_novo === false){
                        dados.fl_impedimento = false
                    }
                }
                if(this.itemAtribuidoParaObsevacao.id > 0 && this.textoNovaObservacao){
                    
                    await AtividadeServico.registrarObsevacao(dados).then(async () => { 
                        
                        this.textoNovaObservacao = null
                        this.exibirModalObservacoes = false;
                        await this.buscarAtribuidasPendentes({idusuario:this.usuario.id})
                        

                    }).catch(error=>{
                        alert('Não foi Possível Registrar!')
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })

                }else{
                    alert('Preencha os campos Obrigatorios!')
                }

        },

       async registrarPrioridades(){
            await Prioridades.buscarPrioridade()
            .then((res)=>{
                const {data} = res;
                data.forEach((e) => {
                const prioridade = {
                    id: e.id,
                    titulo: e.titulo
                }
                    this.selectPrioridade = [...this.selectPrioridade, prioridade]
                })
                this.selectPrioridade.sort(function (a, b) {
                    if (a.titulo > b.titulo) {
                      return 1;
                    }
                    if (a.titulo < b.titulo) {
                      return -1;
                    }
                    return 0;
                  });
                
                const filter = data.filter((prioridade) => prioridade.status !== false);
               this.prioridades = filter;
               this.listadeprojetosFiltro = filter;
            })
            .catch(()=>{

            })
        },

        deletarPrioridade(atividades) {
            this.atividadePrioridade = atividades;
            this.exibirModalDeletarPrioridade = true;
        },

        exibirAnexo(imagem) {
          this.imgSrc = null;
          this.imgSrc = imagem;
          this.exibirModalAnexo = true;
        },

        async confirmarDeletarPrioridade() {
            const { id } = this.atividadePrioridade;
            const attStatus = {
                id,
                status: false
            }
            await Prioridades.atualizarStatusPrioridade(attStatus)
            .then((res) => {
                console.log(res)
                this.registrarPrioridades();
                this.exibirModalDeletarPrioridade = false;
            })
            .catch ((error) => {
                console.log(error);
                this.exibirModalDeletarPrioridade = false;
            })
        },

        downloadFile() {
           this.exibirModalExportarParaExcel = true;
    },

    exportarTabela() {
        const tabela = this.tabelaSelecionada
        if (tabela === 'Atividades') {
            this.exibirModalFiltrosRelatoriosAtividades = true;
        } else {
            const data = this.projetosExportar;
            console.log(data);
            exportarTabela(tabela, data);
            this.exibirModalExportarParaExcel = false;
        }
    },

    concluirExportarTabelaAtividades() {
      const obj = {
        Status: this.status,
        dataEstimativaInicial: this.dataEstimativa.data_inicial,
        dataEstimativaFinal:this.dataEstimativa.data_final,
        flAtribuida: this.Atribuidas,
        dataConclusaoInicial: this.dataConclusao.data_inicial,
        dataConclusaoFinal: this.dataConclusao.data_final
      }

      AtividadeServico.buscarAtividadesParaExportar(obj)
      .then((res) => {
        const data = res.data
        const tabela = this.tabelaSelecionada
        exportarTabela(tabela, data)
        this.exibirModalFiltrosRelatoriosAtividades = false;
        this.exibirModalExportarParaExcel = false;
      })
      .catch((error) => {
        console.log(error);
      })

    },


  
    editarProjeto(item) {
        const dataCriacao = item.data_criacao;
        const converteDataCriacao = new Date(dataCriacao).toLocaleDateString('en-ca');

        const dataConclusao = item.data_conclusao_estimada;
        const converteDataConclusao = new Date(dataConclusao).toLocaleDateString('en-ca');
    
        this.prioridadeEditar.id = item.id;
        this.prioridadeEditar.titulo = item.titulo;
        this.prioridadeEditar.descricao = item.descricao;
        this.prioridadeEditar.idusuario = item.idusuario;
        this.prioridadeEditar.dataCriacao = converteDataCriacao;
        this.prioridadeEditar.dataConclusao = converteDataConclusao;
        this.exibirModalEditarPrioridade = true;
    },

    async salvarProjetoEditado() {
        const {id, dataConclusao, dataCriacao, descricao, idusuario, titulo} = this.prioridadeEditar;
        const data = {
            id,
            descricao,
            idusuario,
            titulo,
            data_conclusao_estimada: dataConclusao + "T00:00:00.000Z",
            data_criacao: dataCriacao + "T00:00:00.000Z"
        }
        await Prioridades.atualizarPrioridade(data)
        .then((res) => {
            console.log(res);
            console.log('atualizada com sucesso');
            this.exibirModalEditarPrioridade = false;
            this.registrarPrioridades();
        })
        .catch((error) => {
            console.log(error)
        })
    },

    async buscarTabelasExcel() {
        const atividades = await AtividadeServico.buscarAtividadesParaExportar();
        this.atividadesExportar = atividades.data;
        const projetos = await Prioridades.buscarProjetosExcel();
        this.projetosExportar = projetos.data;
    }
  },

    computed: {
        
    },
    
  async mounted(){
    this.exibirTituloAtribuida = true;
    this.diaselecionado = dayjs().format('YYYY-MM-DD')
      await this.buscarTabelasExcel(); 
      await this.registrarPrioridades()
      await this.requisitarUsuarios() 
      await this.requisitarAtividadesDisponiveis() 
      await this.buscarAtribuidasPendentes({idusuario:this.usuario.id,dtinicial:this.dtinicialconclusao,dtfinal:this.dtfinalconclusao})
     
  }
}
</script>

<style>
div::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */

}
.title{
font-weight:900;
justify-content: center;
align-items: center;
display: flex;
font-size: medium;
}
.select-input{
width: 100vh;

}
.div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.es-checkbox {
    padding: 0.2rem;
}

.imgPrioridade {
    width: 45px;
}

.imgBug {
    width: 30px;
}

.imagemFeature {
    filter: drop-shadow(0px 0px 2px #222);
    width: 30px;
}

.btnFecharDetalhePrioridade {
    background-color: #1377F2;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 20px;
    margin: 10px;
}

.buttonExportarAtividade {
    background-color: #1377F2;
    width: 150px;
    color: white;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
}

.containerDetalhesPrioridades {
    text-align: center;
    padding: 20px;
    overflow-wrap: break-word;
}

.imgPrioridadeNull {
    width: 20px;
}

.containerBtnNovasPrioridades {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
}

.v-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menuBotes {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.336);
    border-radius: 5px;
    margin-top: 20px;
}

.iconeDeletarPrioridade {
    width: 20px;
}

.imgteste {
  width: 30px;
}

.imgmeet {
  width: 35px;
}

.deletarPrioridadeBtn {
    background-color: #a80000;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 40px;
    margin: 10px;
}

.cancelarDeletarPrioridade {
    background-color: #1377F2;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    margin: 10px;
}

.np-btn {
    background-color: #1377F2;
    width: 150px;
    height: 30px;
    color: white;
    border-radius: 8px;
    margin-top: 20px;
    margin-left: auto;
}

.containerAcoesProjetos {
    display: flex;
}

.buttonDeletePrioridade {
    margin-left: 15px;
}

.textFieldDateFiltroRelatorio {
width: 100%;
border: 1px solid rgb(196, 196, 196);
margin-right: 10px;
margin-left: 5px;
border-radius: 5px;
padding: 4px;
}
#labelDateRelatorioFiltro {
  margin-bottom: 10px;
}

.ContainerinputDataEstimativa {
  display: flex;
  margin-bottom: 20px;
  border-top: 1px solid rgb(218, 218, 218);
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  align-items: center;
}

.inputRadio {
  margin-left: 20px;
  margin-right: 5px;
}

.inputRadio1 {
  margin-right: 5px;
}

.containerTituloAtividadeExportar {
  border-bottom: 1px solid rgb(218, 218, 218);
  margin-bottom: 20px;
  text-align: center;
  padding: 15px;
}

.addHoras {
  width: 45px;
}

.containerFiltrosAtividades {
  padding: 15px;
}

.anexoIcone {
  width: 25px;
}

.ContainerFiltroAtividades {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.buttonFiltrarAtividade {
  width: 200px;
  margin: 20px;
}

.inputFiltroAtividades {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
  height: 40px;
  width: 30%;
  min-width: 200px;
  padding: 8px;
}

.divFiltrosAtividades {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgb(214, 214, 214);
  padding: 10px;
}

.divRadiosFiltrosAtividades {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.labelRadio {
  margin-right: 2px;
}

.RadioTitulo {
  margin-right: 20px;
}
</style>