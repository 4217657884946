<template>
  <v-dialog  persistent v-model="exibirVotacao" width="80vw" max-width="500px">
    <div style="background-color:white;width:100%;display:flex;flex-direction:column;align-items: center;">
    <div class="containerInputs">
      <h2>Votação de atividades</h2>
      <div class="containerDescricao">
        <p><strong>Atividade</strong>: {{atividade}}</p>
        <p><strong>Descrição:</strong> {{descricao ? descricao: 'Nenhuma descrição!'}}</p>
      </div>
      <p>Participantes:</p>
      <p v-if="!dadosSala">Carregando...</p>
      <ul v-if="dadosSala">
       <li  v-for="(usuario, index) in usuariosSala" :key="index">- {{(usuario && usuario.nome)}} {{dadosSala[0].exibir_votos ? usuario.voto : ''}}</li>
      </ul>
      <div>
        <button  class="button" v-for="(voto, index)  in buttonVotos" @click="() => selecionarVoto(voto)" :key="index">{{voto}} {{(Number(voto) > 1 ? 'Horas' : 'Hora')}}</button>
        <button  class="buttonConfirm" @click="this.votar">Confirmar voto</button>
      </div>
      <span class="Selecionado">Voto: {{votoSelecionado ? `${votoSelecionado} ${votoSelecionado > 1 ? 'Horas' : 'Hora'}` : 'Nenhum voto selecionado!'}}</span>
      <span class="Selecionado" v-if="mensagemVotoConfirmado">Voto confirmado com sucesso!</span>
      <span class="Selecionado" v-if="dadosSala[0].exibir_votos">Votação encerrada!</span>
      <div>
        <button class="button" type="button" @click="this.votar">Votar</button>
        <button class="button" type="button" @click="this.reiniciarVotacao">Reiniciar Votação</button>
        <button class="buttonClose" type="button" @click="this.sairDaSala">Sair da sala</button>
      </div>
      <div v-if="dadosSala">
       <p v-show="dadosSala[0].exibir_votos"><strong>Média</strong> - {{media}}</p>
       <button class="button" v-show="dadosSala[0].exibir_votos" type="button" @click="atribuirHorario">Atribuir a horas estimadas</button>
      </div>
    </div>
    </div>
  </v-dialog>
</template>

<script>

import votacao from '../services/votacao.service';

export default {
  name: 'votacao',
  props: ['exibirVotacao', 'sala', 'atividade', 'descricao', 'idAtividade'],
  data() {
    return {
      dadosUsuario: null,
      usuariosSala: [],
      intervalId: null,
      buttonVotos: ['1', '2', '3', '5', '8', '13', '20', '40', '100'],
      intervalSalaId: null,
      dadosSala: null,
      voto: null,
      media: null,
      finalizarvotacao: false,
      confirmado: false,
      atividadeVotar: '',
      votoSelecionado: null,
      mensagemVotoConfirmado: false,
      mensagemVotacaoEncerrada: false,
    }
  },
  created() {

window.addEventListener('beforeunload', async ()  => {
  await votacao.deletar({id: this.dadosUsuario.id})
        .then(() => {
        })
        .catch((error) => {
          console.log('erro', error)
        })  
});
},
  methods: {
    async buscarUsuariosSala() {
      const obj = {
        token: this.dadosUsuario.token_sala
      }
      this.intervalId = setInterval(async () => {
        await votacao.buscar(obj)
        .then((res) => {
          this.usuariosSala = res.data;
        })
        .catch((error) => {
          console.log('erro', error)
        })
      }, 1000)
    },

    async atribuirHorario() {

      const obj = {
        id: this.idAtividade,
        horas_estimadas: Number(this.media)
      }

      await votacao.atribuir(obj)
        .then(() => {
          alert('Média atribuída com sucesso');
        })
        .catch((error) => {
          console.log('erro', error)
        })
    },

    async buscarSala() {
      const obj = {
        token: this.dadosUsuario.token_sala
      }
      this.intervalSalaId = setInterval(async () => {
        await votacao.buscarSalaToken(obj)
        .then((res) => {
          this.dadosSala = res.data;
        })
        .catch((error) => {
          console.log('erro', error)
        })
      }, 1000)

    },

    selecionarVoto(voto) {
      if(voto === '1/2') {
        return this.votoSelecionado = 0.5;
      }
      this.votoSelecionado = Number(voto);
    },

    async votar() {
      const obj = {
        id: this.dadosUsuario.id,
        voto: this.votoSelecionado,
        voto_confirmado: true
      }
      await votacao.votar(obj)
        .then(() => {
          this.verificaFinalizarVotacao();
          this.mensagemVotoConfirmado = true;
        })
        .catch((error) => {
          console.log('erro', error)
        })
    },

    async verificaFinalizarVotacao() {

      if(this.confirmado) {
        clearInterval(this.idSet);
      }

        if(!this.confirmado) {
          this.idSet = setInterval(() => {
            const votosConfirmados = this.usuariosSala.every((e) => e.voto_confirmado)
            if (votosConfirmados) {
              this.confirmado = true;
              this.verificaFinalizarVotacao()
            }
          }, 1000)
        }
      if (this.confirmado) {
        clearInterval(this.idSet);
        this.confirmado = false;
        this.finalizarVotacao();
      }
    },

    async finalizarVotacao() {
      let soma = 0;

      this.usuariosSala.forEach((usuario) => {
        soma += usuario.voto
      })
      const quantidade = this.usuariosSala.filter((usuarios) => usuarios.voto > 0)
      let media = soma / quantidade.length
      this.media = media.toFixed(2);
      const obj = {
        exibirvoto: true,
        token: this.dadosUsuario.token_sala
      }
      await votacao.finalizarVotacao(obj)
        .then(() => {
          this.finalizarvotacao = true
          this.mensagemVotoConfirmado = false;
          this.mensagemVotacaoEncerrada = true;
        })
        .catch((error) => {
          console.log('erro', error)
        })
    },

    async sairDaSala() {
      clearInterval(this.intervalId)
      clearInterval(this.intervalSalaId)
      localStorage.removeItem('sala')
      await this.deletarUser();
      this.$emit('fecharModal')
    },

    async reiniciarVotacao() {

      const obj = {
        exibirvoto: false,
        token: this.dadosUsuario.token_sala
      }
      await votacao.finalizarVotacao(obj)
        .then(() => {
          this.finalizarvotacao = false;
          this.mensagemVotoConfirmado = false;
          this.mensagemVotacaoEncerrada = false;
        })
        .catch((error) => {
          console.log('erro', error)
        })

      await votacao.reiniciar()
        .then(() => {
          
        })
        .catch((error) => {
          console.log('erro', error)
        })
    },

    async AdicionarAtividade() {
      const obj = {
        atividade: this.atividade,
        token: this.dadosUsuario.token_sala
      }
      await votacao.adicionaratt(obj)
        .then(() => {
        })
        .catch((error) => {
          console.log('erro', error)
        })
    },

    async deletarUser(){
      await votacao.deletar({id: this.dadosUsuario.id})
        .then(() => {
        })
        .catch((error) => {
          console.log('erro', error)
        })  
    },

  },

  async mounted(){
   this.atividadeVotar = this.atividade;
   this.dadosUsuario = this.sala;
   await this.buscarUsuariosSala();
   await this.buscarSala();
   await this.reiniciarVotacao();
  },

  unmounted() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalSalaId)
    this.deletarUser();

  },
  async beforeDestroy() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalSalaId)
    await this.deletarUser();

  },

  async destroyed() {
    clearInterval(this.intervalId)
    clearInterval(this.intervalSalaId)
    localStorage.removeItem('sala')
    this.deletarUser();
  }
}
</script>

<style scoped>

.containerInputs {
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  border-radius: 10px;
  margin-top: 20px;
}
.containerVotacao {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.button {
  background-color: #1377F2;
  color: white;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;
}

.buttonClose {
  background-color: #c90101;
  color: white;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;   
}

.inputTextNumber {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.containerDescricao {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buttonConfirm {
  background-color: #21a301;
  color: white;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;
}

.Selecionado {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
